import { Header as BaseHeader, Link, themes } from '@blackbird/ui-base';
import { HelpOutline, Menu, OpenInBrowser } from '@mui/icons-material';
import { Grid, Hidden, IconButton, Tooltip, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { makeStyles } from 'tss-react/mui';

import { LineDescriptionContext } from '@/contexts/line-description';
import { useTranslation } from '@/hooks/use-translation';

import DashboardMenu from '../../views/dashboard/dashboard-menu';
import LanguagePicker from './language-picker';
import UserInfo from './user-info';

const drawerWidth = 240;

const useStyles = makeStyles()((theme) => ({
  barShifted: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    transition: theme.transitions.create(['width', 'margin', 'padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'hidden',
  },
  contrastColor: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  hideSmooth: {
    width: 0,
    margin: 0,
    padding: 0,
  },
  logo: {
    height: '100%',
    display: 'inline-block',
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
    paddingLeft: 16,
    verticalAlign: 'middle',
    '& > svg': {
      height: '100%',
    },
  },
  languagePicker: {
    marginTop: 2,
  },
  lineLink: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  lineIcon: {
    marginRight: 8,
  },
  contrastWithHover: {
    '*': {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    '&:hover *': {
      color: '#FFB200',
    },
  },
  logoLink: {
    display: 'inline-block',
    height: '100%',
    paddingTop: '1em',
    paddingBottom: '1em',
  },
}));

type HeaderProperties = {
  isNavigationOpen: boolean;
  openNavigation: () => void;
};

const HelpButton: React.FC<{ className: string }> = ({ className }) => (
  <IconButton component={Link} href="/help" aria-label="help" className={className}>
    <HelpOutline />
  </IconButton>
);

const Header: React.FC<HeaderProperties> = ({ isNavigationOpen, openNavigation }) => {
  const { classes, cx } = useStyles();
  const router = useRouter();
  const { t } = useTranslation(['line']);

  const isDashboard = router.pathname.includes('dashboard');

  const { lineDescription } = useContext(LineDescriptionContext);

  const left = (
    <Grid container alignItems="center" sx={{ height: '100%' }}>
      <Grid item>
        <IconButton
          aria-label="Menu"
          onClick={() => openNavigation()}
          className={cx(classes.menuButton, classes.contrastWithHover, {
            [classes.hideSmooth]: isNavigationOpen,
          })}
        >
          <Menu />
        </IconButton>
      </Grid>
      <Grid item sx={{ height: '100%' }}>
        <Link href="/" className={classes.logoLink}>
          <img className={classes.logo} src="/images/logo.svg" />
        </Link>
      </Grid>
    </Grid>
  );

  let right = (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <Hidden mdDown>
          <UserInfo alignRight />
        </Hidden>
      </Grid>
      <Grid item>
        <LanguagePicker className={classes.languagePicker} />
      </Grid>
      <Grid item>
        <HelpButton className={classes.contrastWithHover} />
      </Grid>
    </Grid>
  );

  if (isDashboard) {
    right = (
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          {router.query.lineId && (
            <ThemeProvider theme={themes.light}>
              <DashboardMenu lineId={router.query.lineId as string} />
            </ThemeProvider>
          )}
        </Grid>
        <Grid item>
          <Link
            href={{
              pathname: '/line',
              query: {
                lineId: router.query.lineId,
                tab: 'live',
              },
            }}
          >
            <Tooltip title={t(['line:openLine'], { defaultValue: 'Open line' })}>
              <IconButton size="large" className={classes.contrastColor}>
                <OpenInBrowser className={classes.lineIcon} />
                <Typography className={classes.lineLink}>{lineDescription}</Typography>
              </IconButton>
            </Tooltip>
          </Link>
        </Grid>
        <Grid item>
          <LanguagePicker className={classes.languagePicker} />
        </Grid>
        <Grid item>
          <HelpButton className={classes.contrastWithHover} />
        </Grid>
      </Grid>
    );
  }

  return <BaseHeader left={left} right={right} className={cx({ [classes.barShifted]: isNavigationOpen })} />;
};

export default Header;
