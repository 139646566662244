import { themes } from '@blackbird/ui-base';
import { I18Next } from '@blackbird/ui-base/components';
import DateFnsUtils from '@date-io/date-fns';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AppProps as NextAppProps } from 'next/app';
import Head from 'next/head';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Rollbar from 'rollbar';
import { Userpilot } from 'userpilot';

import FillHeight from '@/components/containers/fill-height';
import PageLayout from '@/components/layout/page-layout';
import OutdatedBrowser from '@/components/outdated-browser';
import NewBuildSnackbar from '@/components/snackbars/new-build';
import createEmotionCache from '@/emotion-cache';
import { LanguagesContext } from '@/hocs/with-languages';
import availableLanguages from '@/languages.json';
import getLocale from '@/locale';
import StaticContextProviders from '@/static-context-providers';

// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
interface AppProps extends NextAppProps {
  emotionCache?: EmotionCache;
}

const App: FunctionComponent<AppProps> = (props) => {
  // NOTE: Since we use the hook here, we must wrap a HOC/provider around this
  // component. Alternatively, we could refactor most of the used hooks here
  // into seperate wrappers through isolation.
  const [, { language }] = useTranslation();

  const { Component, emotionCache = clientSideEmotionCache, pageProps = {} } = props;

  useEffect(() => {
    new Rollbar({
      accessToken: process.env.ROLLBAR_CLIENT_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      enabled: process.env.NODE_ENV === 'production',
      payload: {
        environment: process.env.NODE_ENV,
        client: {
          javascript: {
            source_map_enabled: true,
            code_version: process.env.GIT_REVISION,
            guess_uncaught_frames: true,
          },
        },
      },
    });
    Userpilot.initialize('NX-1b60992f');
  }, []);

  return (
    <>
      <Head>
        <title>Blackbird - Smart Data Collection</title>
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
        <meta name="apple-touch-fullscreen" content="yes" />
        <meta name="apple-mobile-web-app-title" content="Blackbird" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <link rel="apple-touch-startup-image" href="/images/startup.png"></link>
      </Head>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={themes.light}>
          <StaticContextProviders>
            <CssBaseline />
            <OutdatedBrowser />
            <NewBuildSnackbar />
            <LocalizationProvider dateAdapter={DateFnsUtils} adapterLocale={getLocale(language)}>
              <LanguagesContext.Provider value={{ availableLanguages }}>
                <PageLayout availableLanguages={availableLanguages}>
                  <FillHeight>
                    <Component {...pageProps} />
                  </FillHeight>
                </PageLayout>
              </LanguagesContext.Provider>
            </LocalizationProvider>
          </StaticContextProviders>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
};

export default I18Next(App);
